<template>
	<div class="inform_list">

		<!-- 发布活动 -->
		<div class="form_center">
			<div class="center_top">
				<div class="top_dian">

				</div>
				<div class="top_text">
					<!-- 通知消息 -->{{$t('TZXX')}}
					<!-- {{$t('add_activity.FBHD')}} -->
				</div>
				<div style="flex:1"></div>
				<a href="javascript:history.back();">
					
					<el-button  size="mini"><i class="el-icon-close" style="font-size: 16px;"></i></el-button>
				</a>
			</div>

			<el-collapse>
				<el-collapse-item v-for="(item,index) in list" :key="item.receive_id" >
					<template slot="title" >
						<div @click="read(item.receive_id,index)" style="width: 100%;">
							<i class="header-icon el-icon-info" style="color: #ff3333;margin-right: 10px;" v-if="item.receive_status==0"></i>
							<i class="header-icon el-icon-info" style="color: #999;margin-right: 10px;" v-else></i>
							{{item.notif_title}}
						</div>
					</template>
					<div>{{item.notif_content}}</div>
					<div>{{$util.rTime(item.update_time)}}</div>
					<!-- <el-image style="width: 100px; height: 100px"
						src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
						fit="cover"></el-image> -->
				</el-collapse-item>
				
			</el-collapse>

			<el-pagination style="margin-top: 30px;"
			    layout="prev, pager, next"
			    :total="total" @current-change="fenye">
			  </el-pagination>
		</div>

	</div>
</template>

<script>
	export default {		
		data() {
			return {
				loading:false,
				currentPage: 1,
				isLast:0,
				pageSize: 15,
				list:[],
				total:1
			};
		},
		created() {
			this.getList();
			
		},
		methods: {
			fenye(e){
				console.log(e);
				this.currentPage=e;
				this.getList();
			},
		
			async read(id,index){
				console.log(id);
				let res=await this.$request.get('/api/operMessage/readMsg',{
						receive_id:id
				});
				this.list[index].receive_status=1;
			},
			async getList(t){
				if(t=='reload'){
					this.currentPage=1;
					this.isLast=0;
						  	
				}
				if(this.isLast==1||this.loading){
					return;
				}
				
				this.loading=true;
				
				let res=await this.$request.post('/api/operMessage/receivePage',{
					currentPage:this.currentPage,
					pageSize:this.pageSize
				});
				this.loading=false;
				this.total=res.data.totalPage;
				this.list=res.data.records;
				
				
			},
		}
	};
</script>

<style lang="less" scoped>
	.ql-container.ql-snow {
		line-height: normal !important;
		height: 200px !important;
		font-size: 14px;
	}

	.inform_list {
		// padding: 30px;
		min-height: 100%;
		background-color: #212121;
		font-size: 14px;
		display: flex;
		justify-content: center;
		// flex-direction: column;
		// align-items: center;
		padding-top: 90px;
		padding-bottom: 90px;

		.form_left {
			display: flex;
			flex-direction: column;
			width: 286px;
			margin-right: 25px;

			.mt20 {
				margin-top: 20px;
			}

			.btn1 {
				background-color: #567BB6;
				border: none;
			}

			.btn2 {
				background-color: #212121;
				border: 1px solid #567BB6;
				color: #fff;
			}

			.el-button {
				margin-left: 0;
				margin-bottom: 12px;
				width: 286px;
			}
		}

		.form_center {
			width: 874px;
			padding: 30px;
			background-color: #fff;
			border-radius: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.center_top {
				height: 40px;
				display: flex;
				align-items: center;
				width: 771px;
				border-bottom: 1px solid #567BB6;
				margin-bottom: 20px;
				position: relative;

				.top_dian {
					width: 5px;
					height: 15px;
					background: #567BB6;
					margin-right: 10px;
				}

				.top_name {
					font-size: 17px;
					font-weight: bold;
				}
			}

		}

		.el-collapse {
			width: 771px;
		}

	}
</style>